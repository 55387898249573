import React from 'react';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import "./App.css";
import abi from './abi';

function App() {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [contractAddress, setContractAddress] = useState(null);
  const [fee, setFee] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState(null);
  const [airdropAmount, setAirdropAmount] = useState(null);
  const [airdropType, setAirdropType] = useState("single");
  const [adminWallet, setAdminWallet] = useState("0x");
  const [chainID, setChainID] = useState(null);
  const [chainName, setChainName] = useState(null);


  function getQueryParams() {
    const search = window.location.search.substring(1);
    const params = search.split('&');
    const queryParams = {};
    for (let i = 0; i < params.length; i++) {
      const pair = params[i].split('=');
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      queryParams[key] = value;
    }
    return queryParams;
  }
  const queryParams = getQueryParams();
  const paramValue = queryParams.aff;


  const fetchData = async () => {
    try {
      const response = await axios.get('data.json');
      setContractAddress(response.data[0].value);
      setFee(response.data[1].value);
      setTitle(response.data[2].value);
      setDescription(response.data[3].value);
      setTokenContract(response.data[4].value);
      setTokenSymbol(response.data[5].value);
      setAirdropAmount(response.data[6].value);
      setAirdropType(response.data[7].value);
      setAdminWallet(response.data[8].value);
      setChainName(response.data[9].value);
      setChainID(response.data[10].value);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }
   }

  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    try{
      const chainIdHex = await ethereum.request({ method: 'eth_chainId' });
      const chainIdDecimal = parseInt(chainIdHex, 16);
      if(chainID !== chainIdDecimal.toString()){
        alert("Please change network to " + chainName);
        return false;
      }
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button className="btn-claim" onClick={connectWalletHandler} tabindex="0" type="button">
        Wallet Connect
      </button>
    )
  }

  const airdropButton = () => {
    return (
      <button className="btn-claim" onClick={airdropHandler} tabindex="0" type="button">
        Claim Airdrop
      </button>
    )
  }

  function isAdmin(){
    if(adminWallet === null || currentAccount === null){
      return false;
    }
    if(adminWallet.toLowerCase() === currentAccount.toLowerCase()){
      return true;
    }
    return false;
  }

  function isEthereumAddress(address) {
    // Ethereum address pattern
    const ethereumAddressPattern = /^(0x)?[0-9a-fA-F]{40}$/;
  
    // Check if the address matches the pattern
    return ethereumAddressPattern.test(address);
  }


  useEffect(() => {
    checkWalletIsConnected();
    fetchData();
  }, [])

  
  const airdropHandler = async() => {
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize payment");
        let getadrp;

        if(isEthereumAddress(paramValue)){
          // alert("i'm affiliate");
          getadrp = await contract.airdropTokensAff(paramValue, {value: fee});
        }else{
          // alert("i'm without affiliate");
          getadrp = await contract.airdropTokens({value: fee});
        }
        
        console.log(contract);
		if(getadrp){
			alert("Congratulations, you got our airdrop, you will receive your tokens very soon");
		}else{
      if(airdropType === "single"){
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee or your wallet already have this token!");
      }else{
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee!");
      }
		}
      }
    }catch(err){
      if(airdropType === "single"){
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee or your wallet already have this token!");
      }else{
        alert("Something wrong, Maybe you don't have enough balance for the transaction fee!");
      }
    	console.log(err);
    }

  }

  const withdrawTokens = async() => {
    // alert(contractAddress);
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize withdraw");
        let withdrawRes = await contract.withdrawTokens(adminWallet, tokenContract);
        
        if(withdrawRes){
          alert("Congratulations, you will receive your tokens very soon");
        }else{
          alert("Something wrong, Only the owner wallet who can withdraw.");
        }
          }
        }catch(err){
        alert("Something wrong, Only the owner wallet who can withdraw.");
          console.log(err);
        }

  }

  return (
    <div className="div-self">
      <div className="card-s">
        <div className="card-header-s">
          <div className="flex-logo">
            <div className="logo-div">
              <img
                src="logo.png"
                className="company-logo"
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="d-flex-auto">
            <span className="header-text-s">{title}</span>
          </div>
        </div>

        <div className="mt-15">
          <img
            className="banner-img"
            src="banner.png"
            height="200"
            alt="Banner"
          />
        </div>

        <div>
          <div className="text-center">
            <p className="para-s">
              {description}
            </p>
          </div>
          <div className="contract-div">
            <p>
              Contract :{" "}
              <span className="contract-font">
                {tokenContract}
              </span>
            </p>
          </div>
          <h6 className="claim-h">Claim : {airdropAmount} {tokenSymbol}</h6>

          {currentAccount ? airdropButton() : connectWalletButton()}

          <br />
          <br />
          <div className="contract-div">
            <p>
              Affiliate Link ( Earn 1 SDT )
              <p>{window.location.href}{currentAccount ? "?aff=" + currentAccount : "?aff=YOUR-WALLET-ADDRESS"}</p>
            </p>
          </div>

          <br />
          <br />
          {isAdmin() && 
            <div className="contract-div">
              <h5>Admin Area</h5>
              <p>
                Owner wallet only who can withdraw the tokens from the airdrop contract.
              </p>
              <button className="btn-claim" onClick={withdrawTokens}>
                Withdraw Tokens
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
}

export default App;
